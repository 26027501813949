'use strict';

angular.module('webPartnerPortalApp')
  .controller('PartnerModalCtrl', ['partner', 'newPartner', '$http', '$modalInstance', '$location','$window',
  function(partner, newPartner, $http, $modalInstance, $location, $window) {
    this.partner = partner;
    this.newPartner = newPartner;
    var vm = this;
    vm.errorMsg = '';

    vm.capitalizeFirstLetter = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    this.submit = function () {
      if (newPartner) {
        $http.post('api/partners/create', this.partner)
          .success(function(res){
            $location.path('/partner/' + res.body.campaign_source_id);
            $modalInstance.close();
          })
          .error(function(err){
            vm.errorMsg = vm.capitalizeFirstLetter(err.body.message);
            if(err.body.code === '') {
              vm.errorMsg = 'Sorry, We cannot process your request right now. Please try again later';
            }
          });
      } else {
        var updatedPartner = angular.copy(this.partner);
        delete updatedPartner.partner_mapping;
        $http.put('api/partners/update/' + this.partner.campaign_source_id,updatedPartner)
          .success(function(res){
            $window.location.href = '/partner/' + res.body.campaign_source_id;
            $modalInstance.close();
          })
          .error(function(err){
            vm.errorMsg =  vm.capitalizeFirstLetter(err.body.message);
            if(err.body.code === '') {
              vm.errorMsg = 'Sorry, We cannot process your request right now. Please try again later';
            }
          });
      }
    };
    this.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  }]);
